<template>
    <div>
        <div  class="content-onglets table">  
            <div>                                   
            <table class="comparateur2">   
                    <thead>
                        <tr >
                            <th width="5%" class="first">&nbsp; </th>
                            <th width="34%"> {{$t('BP_presentation')}} </th>
                            <th width="8%" v-if="trs_type_demande == 'INS'">SS</th>
                            <th width="8%" v-if="trs_type_demande == 'INS'">COLL</th>
                            <th width="8%" v-if="trs_type_demande == 'INS'">T2A</th>
                            <th width="8%" v-if="trs_type_demande == 'INS'">Retro</th>
                            <th width="13%" v-if="trs_type_demande == 'INS'"> {{$t('Statut')}} </th>
                            <th width="8%" v-if="trs_type_demande == 'INS'">{{$t('AP')}}</th>
                        </tr>
                    </thead>
                    <tbody :key="'link'+key+'body'" v-for="(item, key) in data">                                               
                        <tr>
                            <td>                           
                                <router-link :to="'/detail/'+item.bp_id+'/bp'" target="_blank" rel="noopener">
                                    <em class="icons icon-plus-m"></em>
                                </router-link>
                            </td>
                            <td>
                                {{item['bp_presentation']}}
                                <br/>
                                CIP 13 : {{item['bp_cip13']}}
                            </td>
                            <td v-if="trs_type_demande == 'INS'">{{item['rad_ss']}} </td>
                            <td v-if="trs_type_demande == 'INS'">{{item['rad_coll']}} </td>
                            <td v-if="trs_type_demande == 'INS'">{{item['rad_t2a']}} </td>
                            <td v-if="trs_type_demande == 'INS'">{{item['rad_retro']}} </td>
                            <td v-if="trs_type_demande == 'INS'">{{item['bp_statut_generique'] ? $t('boite.status_generique.'+item['bp_statut_generique']) : "-" }} </td>
                            <td v-if="trs_type_demande == 'INS'">{{item['bp_atu'] ? $t('yes') : "-"}} </td>
                        </tr>                    
                    </tbody>
            </table> 
            </div>                    
        </div> 
    </div>
</template>

<script>

export default {
    name : "linkBoitePresentation",
    props : {
        data : {
            style : Array
        },
        trs_type_demande : {
            style : String
        }
    }
}
</script>

<style>

</style>